<template>
  <!-- TODO is this something we can add to indicate trend? -->
  <!-- <v-sparkline
    :value="[80, 79]"
    :labels="['Previous', 'Current']"
    :label-size="14"
    color="black"
    padding="28"
    smooth
    height="150"
    line-width="2"
  /> -->

  <!-- Basic card -->
  <!-- <v-card class="text-left mx-4 mt-4" :to="`squads/${squad._id}`">
    <v-card-title>{{ squad.name }}</v-card-title>
    <v-card-text class="flex">
      <div class="flex-1 m-2">
        <div class="font-bold text-base mb-2">
          Members ({{ squad.users.length }})
        </div>
        <v-divider />
        <div
          :key="index"
          v-for="(group, index) in userGroups"
          class="text-base mt-2"
        >
          {{ group.count }} x {{ group.name }}
        </div>
        <div></div>
      </div>
      <div class="flex-1 m-2">
        <div class="font-bold text-base mb-2">Sprint Capacity</div>
        <v-divider />
        <div class="text-base mt-2">
          Current: {{ squad.capacity.total.sprint }}
        </div>
        <div class="text-base mt-2">
          Previous: {{ squad.capacity.previous.sprint }}
        </div>
      </div>
      <div class="flex-1 m-2">
        <div class="font-bold text-base mb-2">Sprint Cost</div>
        <v-divider />
        <div class="text-base mt-2">Current:</div>
        <div class="text-base mt-2">Previous:</div>
      </div>
    </v-card-text>
  </v-card> -->

  <!-- Minor upgrade card -->
  <v-card class="p-4" :to="`squads/${squad._id}`">
    <v-card-title class="pr-0">{{ squad.name }}</v-card-title>
    <v-card-text>
      <div>
        <img
          v-for="(user, userIndex) in squad.users"
          :key="userIndex"
          class="inline rounded-full h-12 w-12"
          role="none"
          :src="user.profileImageUrl"
        />
      </div>

      <div class="flex mt-2">
        <div class="flex-1">
          <!-- <v-card class="h-full"> -->
            <v-card-title class="p-2">Sprint Capacity</v-card-title>
            <v-card-text class="p-2 pt-0">
              <div>Current: {{ squad.capacity.total.sprint }}</div>
              <div>Previous: {{ squad.capacity.previous.sprint }}</div>
            </v-card-text>
          <!-- </v-card> -->
        </div>
        <div class="flex-1 ml-2">
          <!-- <v-card class="h-full"> -->
            <v-card-title class="p-2"
              >Members ({{ squad.users.length }})</v-card-title
            >
            <v-card-text class="p-2 pt-0">
              <div :key="index" v-for="(group, index) in userGroups">
                {{ group.count }} x {{ group.name }}
              </div>
            </v-card-text>
          <!-- </v-card> -->
        </div>
        <div class="flex-1 ml-2">
          <!-- <v-card class="h-full"> -->
            <v-card-title class="p-2">Sprint Cost</v-card-title>
            <v-card-text class="p-2 pt-0">
              <div>Current: {{ squad.capacity.total.sprint }}</div>
              <div>Previous: {{ squad.capacity.previous.sprint }}</div>
            </v-card-text>
          <!-- </v-card> -->
        </div>
      </div>
    </v-card-text>
  </v-card>

  <!-- <v-card>

  </v-card> -->
</template>
<script>
export default {
  name: "SquadCard",
  props: {
    squad: Object,
  },
  computed: {
    userGroups() {
      const userGroups = [];
      this.squad.users.forEach((user) => {
        const groupIndex = userGroups.findIndex(
          (group) => group.name == user.jobTitle
        );

        if (groupIndex >= 0) {
          userGroups[groupIndex].count++;
        } else {
          userGroups.push({
            name: user.jobTitle,
            count: 1,
          });
        }
      });

      return userGroups;
    },
  },
};
</script>